import { web_path } from '../constants/web_path';

type RouteType = {
  pattern: string;
  page: string;
  title?: string;
  category?: string;
  type?: string;
};

/**
 * @deprecated pages/ 디렉토리 하위 파일 시스템으로 변경되어 더 이상 여기에 추가하지 않아도 됩니다.
 * title값은 public/locales 내의 routeTitle.json에 우선 추가해야함
 */
const routes: RouteType[] = [
  { pattern: '/z-lounge/epick/profiles/settings', page: 'z-lounge/epick/profiles/settings', title: '' },
  { pattern: '/partners', page: 'partners/root' },
  { pattern: '/partners/:sub_path', page: 'partners/sub' },
  { pattern: web_path.user_locale, page: 'user-locale' },
  { pattern: '/not-support', page: 'not-support' },
  { pattern: '/login', page: 'auth/login' },
  { pattern: '/cart', page: 'cart', category: 'cart' },
  { pattern: '/event', page: 'event', title: '회원님을 위한 혜택', category: 'order-sheet' },
  { pattern: '/events/showcase-vol5-0224', page: 'events/showcase-vol5-0224' },
  { pattern: '/events/:uuid', page: 'events' },
  { pattern: '/events/:uuid/reply/:reply_uuid', page: 'events/reply' },
  { pattern: '/coupon/box/:shop_id', page: 'coupon/downloads', title: '쿠폰받기', category: 'coupon' },
  { pattern: '/coupon/user-box', page: 'coupon/box', title: '쿠폰' },
  { pattern: '/coupon/user-box/search', page: 'coupon/box/search' },
  { pattern: '/coupon/user-box/expired', page: 'coupon/box/expired' },
  { pattern: '/coupon/issue', page: 'coupon/issue', title: '쿠폰등록' },
  { pattern: '/coupon/issue-redirect', page: 'coupon/issue-redirect' },
  { pattern: '/coupon/detail/:user_account_coupon_policy_id', page: 'coupon/detail', title: '쿠폰상세' },
  { pattern: '/conv-payment-method', page: 'conv-payment-method', title: '입금방법' },
  { pattern: '/my-pick/main', page: 'my-pick/main', title: '마이픽쿠폰' },
  { pattern: '/my-pick/my-coupon', page: 'my-pick/my-coupon' },
  { pattern: '/my-pick/select', page: 'my-pick/select' },
  { pattern: '/my-pick/search', page: 'my-pick/search' },
  { pattern: '/my-pick/tutorial', page: 'my-pick/tutorial' },
  { pattern: '/my-pick/user-requested', page: 'my-pick/user-requested' },
  { pattern: '/my-pick/5-tab-banner', page: 'my-pick/5th-tab-banner', title: '마이픽쿠폰 배너 이미지' },
  { pattern: '/raffle/main', page: 'raffle/main', title: '래플' },
  { pattern: '/raffle/detail/:raffle_id', page: 'raffle/detail', title: '래플' },
  { pattern: '/raffle/result/:raffle_id', page: 'raffle/result', title: '응모결과' },
  { pattern: '/raffle/preview/result', page: 'raffle/result/preview', title: '응모결과' },
  { pattern: '/raffle/winners/:promotion_id', page: 'raffle/winners', title: '당첨자 전체보기' },
  { pattern: '/raffle/history', page: 'raffle/history', title: '래플 응모현황' },
  { pattern: '/experience/main', page: 'experience/main', title: '지그재그 체험단' },
  { pattern: '/experience/history', page: 'experience/history', title: '내 체험단 활동' },
  { pattern: '/experience/detail/:promotion_product_id', page: 'experience/detail' },
  { pattern: '/experience/apply/:promotion_product_id', page: 'experience/apply', title: '체험단 신청' },
  { pattern: '/experience/result/:promotion_product_id', page: 'experience/result', title: '신청완료' },
  { pattern: '/mileage', page: 'mileage', title: '마일리지' },
  { pattern: '/mileage/expire', page: 'mileage/expire', title: '마일리지' },
  { pattern: '/mileage/guide', page: 'mileage/guide', title: '마일리지 사용법' },
  { pattern: '/address-book', page: 'address-book/main', title: '배송지 목록' },
  { pattern: '/address-book/edit/new', page: 'address-book/edit', title: '배송지 추가' },
  { pattern: '/address-book/edit/:address_id', page: 'address-book/edit', title: '배송지 수정' },
  { pattern: '/postcode', page: 'postcode', title: '우편번호 검색' },
  { pattern: '/cart-checkout-bridge', page: 'cart-checkout-bridge', title: '구매 전 이 상품 어때요?' },
  {
    pattern: '/cart-checkout-bridge/zexpress',
    page: 'cart-checkout-bridge/zexpress',
    title: '구매 전 이 상품 어때요?',
  },
  {
    pattern: '/cart-checkout-bridge/zigzinsale',
    page: 'cart-checkout-bridge/zigzinsale',
    title: '구매 전 이 상품 어때요?',
  },
  {
    pattern: '/postcode/business-juso-response',
    page: 'postcode/business-juso-response',
  },
  {
    pattern: '/order-sheets/:uuid',
    page: 'order-sheet',
    title: '주문/결제',
    type: 'checkout',
    category: 'order-sheet',
  },
  {
    pattern: '/order-completed/:order_number',
    page: 'order-completed',
    title: '결제',
    type: 'order_completed',
    category: 'order',
  },
  { pattern: '/checkout-failed', page: 'checkout-failed', title: '결제', type: 'checkout', category: 'order' },
  { pattern: '/order-item-inquiries', page: 'order-item-inquiries/main', title: '문의 내역', category: 'order' }, // need loggedin
  { pattern: '/order-item-inquiries/qna', page: 'order-item-inquiries/qna', title: '문의 내역', category: 'order' }, // need loggedin
  { pattern: '/order-item-inquiries/new', page: 'order-item-inquiries/new', title: '문의하기', category: 'order' }, // need loggedin
  {
    pattern: '/order-item-inquiries/qna/new',
    page: 'order-item-inquiries/qna-new',
    title: '문의하기',
    category: 'order',
  }, // need loggedin
  { pattern: '/orders/:order_number', page: 'orders/show', title: '주문 상세', category: 'order' }, // need loggedin
  {
    pattern: '/order-item-requests/:order_item_request_number/cancel-info',
    page: 'order-item-requests/cancel-info',
    title: '취소 정보',
    category: 'order-request',
  }, // need loggedin
  {
    pattern: '/order-item-requests/:order_item_request_number/return-info',
    page: 'order-item-requests/return-info',
    title: '반품 정보',
    category: 'order-request',
  }, // need loggedin
  {
    pattern: '/order-item-requests/:order_item_request_number/exchange-info',
    page: 'order-item-requests/exchange-info',
    title: '교환 정보',
    category: 'order-request',
  }, // need loggedin
  {
    pattern: '/order-items/:order_item_number/shipping-schedule-delay-info',
    page: 'order-items/shipping-schedule-delay-info',
    title: '배송연기 안내',
    category: 'order',
  }, // need loggedin
  {
    pattern: '/order-items/:order_item_number/shipping-defer',
    page: 'order-items/shipping-defer',
    title: '배송보류 안내',
    category: 'order',
  }, // need loggedin
  { pattern: '/orders/:order_number/confirm', page: 'orders/confirm', title: '구매확정', category: 'order' }, // need loggedin
  { pattern: '/orders/:order_number/confirm-completed', page: 'orders/confirm-completed', category: 'order' },
  { pattern: '/orders/:order_number/cancel', page: 'orders/cancel', title: '미입금 주문 취소', category: 'order' }, // need loggedin
  {
    pattern: '/orders/:order_number/cancel-completed',
    page: 'orders/cancel-completed',
    title: '미입금 주문 취소',
    category: 'order',
  }, // need loggedin
  {
    pattern: '/orders/:order_number/request-cancel',
    page: 'orders/request/cancel',
    title: '주문 취소',
    category: 'order-request',
  }, // need loggedin
  {
    pattern: '/orders/:order_number/request-return',
    page: 'orders/request/return',
    title: '주문 반품',
    category: 'order-request',
  }, // need loggedin
  {
    pattern: '/orders/:order_number/request-exchange',
    page: 'orders/request/exchange',
    title: '주문 교환',
    category: 'order-request',
  }, // need loggedin
  { pattern: '/pay/simple_bank', page: 'pay/simple_bank', title: '계좌 간편결제', type: 'pay', category: 'order' },
  { pattern: '/pay-completed', page: 'pay-completed', category: 'order' },
  { pattern: '/pay-failed', page: 'pay-failed', category: 'order' },
  { pattern: '/simple-pay/register', page: 'simple-pay/register', title: '간편결제 등록', category: 'simple-pay' },
  { pattern: '/simple-pay/terms', page: 'simple-pay/terms', title: '간편결제 등록', category: 'simple-pay' },
  { pattern: '/simple-pay/banks', page: 'simple-pay/banks', title: '간편결제 등록', category: 'simple-pay' },
  {
    pattern: '/simple-pay/certification',
    page: 'simple-pay/certification',
    title: '간편결제 등록',
    category: 'simple-pay',
  },
  { pattern: '/simple-pay/ars', page: 'simple-pay/ars', title: '간편결제 등록', category: 'simple-pay' },
  { pattern: '/simple-pay/ars-failed', page: 'simple-pay/ars-failed', title: '간편결제 등록', category: 'simple-pay' },
  {
    pattern: '/simple-pay/change-password',
    page: 'simple-pay/change-password',
    title: '결제 비밀번호 변경',
    category: 'simple-pay',
  },
  {
    pattern: '/simple-pay/verify-password',
    page: 'simple-pay/verify-password',
    title: '계좌 간편결제',
    category: 'simple-pay',
  },
  { pattern: '/simple-pay/management', page: 'simple-pay/management', title: '간편결제 관리', category: 'simple-pay' },
  { pattern: '/points', page: 'points/main', title: '포인트', category: 'point' }, // need loggedin
  { pattern: '/points/main/expired', page: 'points/main/expired', title: '포인트', category: 'point' },
  { pattern: '/catalog/products/:catalog_product_id', page: 'products/details' },
  { pattern: '/catalog/__preview/:catalog_product_id', page: 'products/details' },

  { pattern: '/products/cross-seller-product/:catalog_product_id', page: 'products/cross-seller-product' },

  { pattern: '/p/:catalog_product_id', page: 'products/details' },
  { pattern: '/catalog/product/shipping-info', page: 'products/shipping-info' },
  { pattern: '/products/global-size', page: 'products/international-size' },
  { pattern: '/user-refund-account', page: 'user-refund-account', title: '환불계좌', category: 'refund' },
  {
    pattern: '/order-item/:order_item_number/shipping-tracking',
    page: 'shipping-tracking/show-shipping',
    title: '배송 현황',
    category: 'order',
  },
  {
    pattern: '/order-item/:order_item_number/return-shipping-tracking',
    page: 'shipping-tracking/show-return',
    title: '반품 현황',
    category: 'order',
  },
  { pattern: '/membership', page: 'membership', title: '' },
  { pattern: '/low-version', page: 'misc/low-version', title: '' },
  { pattern: '/notice', page: 'notice', title: '공지사항' },
  { pattern: '/notice/:id', page: 'notice/details', title: '' },
  { pattern: '/personal-recommended', page: 'personal-recommended', title: '' },
  { pattern: web_path.root, page: 'home' },
  { pattern: web_path.home, page: 'home' },
  { pattern: web_path.search, page: 'search' },
  { pattern: web_path.orders, page: 'orders/main' },
  { pattern: web_path.auth.find_email, page: 'auth/find-email' },
  { pattern: web_path.auth.find_email_result, page: 'auth/find-email-result' },
  { pattern: web_path.auth.find_account_by_tel, page: 'auth/find-account-by-tel' },
  { pattern: web_path.auth.find_account_by_authentication, page: 'auth/find-account-by-authentication' },
  { pattern: web_path.auth.find_account_by_email, page: 'auth/find-account-by-email' },
  { pattern: web_path.auth.find_account_result, page: 'auth/find-account-result' },
  { pattern: web_path.auth.login, page: 'auth/login' },
  { pattern: web_path.auth.email_login, page: 'auth/email-login' },
  { pattern: web_path.auth.email_connect, page: 'auth/email-connect' },
  { pattern: web_path.auth.tel_login, page: 'auth/tel-login' },
  { pattern: web_path.auth.adview_login, page: 'auth/adview-login' },
  { pattern: web_path.auth.find_password, page: 'auth/find-password' },
  { pattern: web_path.auth.reset_password, page: 'auth/reset-password/locale' },
  { pattern: web_path.auth.reset_password_done, page: 'auth/reset-password/locale/done' },
  { pattern: web_path.auth.reset_password_invalid_code, page: 'auth/reset-password/locale/invalid-code' },
  { pattern: web_path.auth.sign_up_email, page: 'auth/sign-up-email' },
  { pattern: web_path.auth.sign_up_done, page: 'auth/sign-up/done' },
  { pattern: web_path.auth.sign_up_exist_account, page: 'auth/sign-up/exist-account' },
  { pattern: web_path.auth.write_email, page: 'auth/write-email' },
  { pattern: web_path.auth.terms_agreement, page: 'auth/terms-agreement' },
  { pattern: web_path.auth.mobile_authentication, page: 'auth/mobile-authentication' },
  { pattern: web_path.auth.profile, page: 'auth/profile' },
  { pattern: web_path.auth.edit_profile, page: 'auth/edit-profile' },
  { pattern: web_path.auth.connect_account, page: 'auth/connect-account' },
  { pattern: web_path.auth.duplicate_social_account, page: 'auth/duplicate-social-account' },
  { pattern: web_path.auth.redirect_scope, page: 'auth/redirect-scope' },
  { pattern: web_path.auth.add_kakao_channel, page: 'auth/add-kakao-channel' },
  { pattern: web_path.auth.withdrawal, page: 'auth/withdrawal/main' },
  { pattern: web_path.auth.withdrawal_reason, page: 'auth/withdrawal/reason' },
  { pattern: web_path.auth.recertification, page: 'auth/recertification' },
  { pattern: web_path.auth.error, page: 'auth/error' },
  { pattern: web_path.auth.error_sns, page: 'auth/error/sns' },
  { pattern: web_path.auth.activate_account, page: 'auth/activate-account' },
  { pattern: web_path.auth.second_auth, page: 'auth/second-authentication' },
  { pattern: web_path.auth.second_auth_password, page: 'auth/second-authentication/password' },
  { pattern: web_path.auth.second_auth_email, page: 'auth/second-authentication/email' },
  { pattern: web_path.auth.second_auth_authentication, page: 'auth/second-authentication/authentication' },
  { pattern: web_path.auth.talk_student_terms_agreement, page: 'auth/talk-student/terms-agreement' },
  { pattern: web_path.auth.talk_student_authentication, page: 'auth/talk-student/authentication' },
  { pattern: web_path.auth.talk_student_submit, page: 'auth/talk-student/submit' },
  { pattern: web_path.auth.error_talk_student, page: 'auth/error/talk-student' },
  { pattern: web_path.my_page.root, page: 'my-page/main' },
  { pattern: web_path.categories.root, page: 'categories/menu' },
  { pattern: web_path.categories.landing, page: 'categories/list' },
  { pattern: web_path.picks, page: 'picks' },
  { pattern: web_path.my_review_list, page: 'my-review/list' },
  { pattern: web_path.review_detail, page: 'review/detail' },
  { pattern: '/reviewer/:reviewer_id/list', page: 'review/reviewer-review-list' },
  { pattern: '/review/:order_item_number/write', page: 'review/write', title: '리뷰작성' },
  { pattern: '/review/completed', page: 'review/completed', title: '리뷰작성완료' },
  { pattern: '/review/list/:product_id', page: 'review/list' },
  { pattern: '/review/gallery/:product_id', page: 'review/gallery' },
  { pattern: '/review-report/:product_review_id', page: 'review-report' },
  { pattern: '/reviewer-ranking/main', page: 'review/reviewer-ranking/main', title: '리뷰어 랭킹' },
  { pattern: '/reviewer-ranking/intro', page: 'review/reviewer-ranking/intro' },
  { pattern: '/beauty', page: 'beauty', title: '뷰티' },
  { pattern: '/beauty/new-arrivals/:id', page: 'beauty/new-arrivals', title: '뷰티 신상' },
  { pattern: '/brand', page: 'brand', title: '브랜드' },
  { pattern: '/brand/shop-list', page: 'brand/shop-list', title: '전체 브랜드' },
  { pattern: '/brand/categories', page: 'brand/categories', title: '브랜드 CLP' },
  { pattern: '/authentication', page: 'authentication', title: '본인인증' },
  { pattern: '/authentication/verify', page: 'authentication/verify', title: '본인인증' },
  { pattern: '/authentication/failed', page: 'authentication/failed', title: '본인인증' },
  { pattern: '/auth/apple-callback-app-connect', page: 'auth/apple-callback-app-connect' },
  { pattern: '/auth/apple-callback', page: 'auth/apple-callback' },
  { pattern: '/:main_domain', page: 'shop/main' },
  { pattern: '/:main_domain/search', page: 'shop/search' },
  { pattern: '/:main_domain/category/:category_id', page: 'shop/category-detail' },
  { pattern: '/:main_domain/collection/:collection_id', page: 'shop/collection-detail' },
  { pattern: '/:main_domain/zigzin-shipping', page: 'shop/zigzin-shipping' },
  { pattern: '/:main_domain/best', page: 'shop/best-detail' },
  { pattern: '/:main_domain/best/:category_id', page: 'shop/best-detail' },
  { pattern: '/user-locale', page: 'user-locale' },
  {
    pattern: '/kakaopay-banners/mypage',
    page: 'kakaopay-banners/mypage',
    title: '',
  },
  { pattern: '/profile/body', page: 'profile/body', title: '체형정보' },
  { pattern: '/profile/body/agreement', page: 'profile/body/agreement', title: '맞춤정보 수집 및 이용 동의' },
  { pattern: '/advertisement/exhibition/:uuid', page: 'advertisement/exhibition' },
  { pattern: '/advertisement/bridge/:ad_id/:creative_id', page: 'advertisement/bridge' },
  { pattern: '/link/bridge', page: 'link/bridge' },
  { pattern: '/store-pick/detail', page: 'store-pick/detail' },
  { pattern: '/rediret', page: 'redirect' },
];

export default routes;
