import pathToRegexp from 'path-to-regexp';

import { I18nKey } from '@common/i18n';

import ROUTES from '../../app/routes';
import { RouteType, ZpayCategory, ZpayHeaderType } from './types';

// Reference: https://github.com/fridays/next-routes/blob/master/src/index.js

export class Route implements RouteType {
  pattern: string;
  page: string;
  title?: I18nKey<'routeTitle'>;
  type?: ZpayHeaderType;
  regex: RegExp;
  keys: pathToRegexp.Key[];
  keyNames: Array<string | number>;
  category?: ZpayCategory;

  constructor({ pattern, page, title, type, category }: RouteType) {
    this.pattern = pattern;
    this.page = '/' + page;
    this.keys = [];
    this.title = title;
    this.type = type;
    this.regex = pathToRegexp(this.pattern, this.keys);
    this.keyNames = this.keys.map((key) => key.name);
    this.category = category;
  }

  match(path: string) {
    const values = this.regex.exec(path);
    if (values) {
      return this.valuesToParams(values.slice(1));
    }
  }

  valuesToParams(values: string[]) {
    return values.reduce((params, val, i) => {
      if (!val) {
        return params;
      }
      return Object.assign(params, { [this.keys[i].name]: decodeURIComponent(val) });
    }, {});
  }
}

export const routes: Route[] = ROUTES.reduce((result: Route[], route: RouteType) => {
  result.push(new Route(route), new Route({ ...route, pattern: '/app' + route.pattern }));
  return result;
}, []);
