import { isBrowser } from '@croquiscom/web2app';

import { web_path } from '../../constants/web_path';
import config from '../config';
import { checkZPayFunctionExists } from '../zigzag_app_event';
import { Route, routes } from './Route';

import { ParsedUrlQuery } from 'querystring';
import { parse, UrlObject, UrlWithParsedQuery } from 'url';

const zigzagService = [
  'https://posty.kr',
  'https://alpha.posty.kr',
  'https://beta.posty.kr',
  'https://fashionbykakao.com',
  'https://alpha.fashionbykakao.com',
  'https://beta.fashionbykakao.com',
];

export const match = (url: string | UrlObject) => {
  const parsedUrl = typeof url === 'string' ? parse(url, true) : (url as UrlWithParsedQuery);
  const { pathname, query } = parsedUrl;
  return routes.reduce<{ query: ParsedUrlQuery; parsedUrl: UrlWithParsedQuery; route?: Route }>(
    (result, route) => {
      if (result.route) {
        return result;
      }
      const params = route.match(pathname!);
      if (!params) {
        return result;
      }
      return { ...result, route, params, query: { ...query, ...params } };
    },
    { query, parsedUrl },
  );
};

export const hasHashInUrl = () => {
  return location.href.indexOf('#') >= 0;
};

export const getUrlByPath = (pathname: string) => {
  if (isBrowser()) {
    pathname =
      checkZPayFunctionExists(window) || document.referrer.indexOf(`${window.location.origin}/app`) >= 0
        ? `/app${pathname}`
        : pathname;
    return `${window.location.origin}${pathname}`;
  }
  return pathname;
};

export const checkXSSUrl = (url: string) => {
  const isZigzagService =
    url.startsWith(`${config.appUrl}`) ||
    url.includes('dev.zigzag.kr') ||
    zigzagService.filter((value) => url.startsWith(value)).length > 0;

  if (isZigzagService) {
    return url;
  } else if (match(url)?.route) {
    return url;
  } else {
    return web_path.root;
  }
};
